<template>
  <div class="customer">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <!-- <el-breadcrumb-item :to="{ path: '/user/my-collect' }"
          >我的收藏</el-breadcrumb-item
        > -->
        <el-breadcrumb-item :to="{ path: '/user/my-collect/customer' }"
          >我的客户</el-breadcrumb-item
        >
        <el-breadcrumb-item
          :to="{
            path: `/user/my-collect/customer/detail?id=${this.$route.query.clientId}`,
          }"
          >我的客户详情</el-breadcrumb-item
        >
        <el-breadcrumb-item>项目跟踪记录详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="customer-container">
      <div class="customer-title-box">
        <div>项目信息</div>
        <div class="title-right" @click="handleAdd">
          <img
            src="@/assets/img/user/baijia.png"
            width="100%"
            height="100%"
            @dragstart.prevent
            class="img-setting"
          />
          <span class="customer">新增跟踪记录</span>
        </div>
      </div>
      <div class="divider"></div>
      <!-- 项目信息盒子 -->
      <div class="customer-info">
        <div class="info-top">
          <div class="info-item">
            <span class="info-title"
              ><span>项目名称</span
              ><span class="title">{{ info.projectName }}</span></span
            >
            <span class="info-title"
              ><span class="with">项目类型</span
              ><span class="title">{{ info.projectType }}</span></span
            >
          </div>
          <div class="info-item">
            <span class="info-title"
              ><span>投资额(万元)</span
              ><span class="title">{{ info.investmentAmount }}</span></span
            >
            <span class="info-title"
              ><span class="with">决策人信息</span
              ><span class="title">{{ info.decisionMakerInfo }}</span></span
            >
          </div>
          <div class="info-item">
            <span class="info-title"
              ><span>可联合资源</span
              ><span class="title">{{ info.unionResources }}</span></span
            >
            <span class="info-title"
              ><span class="with">主要/潜在竞争者</span
              ><span class="title">{{ info.competitor }}</span></span
            >
          </div>
        </div>
        <div class="info-bottom">
          <div class="info-item">
            <span class="info-title"
              ><span>建设内容</span
              ><span class="title">{{ info.constructionContent }}</span></span
            >
          </div>
          <div class="info-item">
            <span class="info-title"
              ><span>备注</span><span class="title">{{ info.remarks }}</span></span
            >
          </div>
        </div>
      </div>
      <div class="customer-title-box">
        <div>跟踪情况</div>
      </div>
      <div class="divider"></div>
      <div v-if="total == 0" style="margin: 103px 0">
        <empty name="暂无数据"></empty>
      </div>
      <!-- 跟踪情况盒子 -->
      <div class="record">
        <div class="record-box">
          <div class="record-item" v-for="(item, index) in tableData" :key="index">
            <div class="item-top">
              <div class="left">
                <div class="time">{{ item.createDate }}</div>
                <img
                  src="@/assets/img/user/dot.png"
                  width="100%"
                  height="100%"
                  @dragstart.prevent
                  class="img-setting"
                />
                <span class="title">提交人：{{ item.createByName }}</span>
              </div>
              <div class="right">
                <div class="title-right" @click="handleEdit(item)">
                  <img
                    src="@/assets/img/user/edit.png"
                    width="100%"
                    height="100%"
                    @dragstart.prevent
                    class="img-setting"
                  />
                  <span class="customer">编辑</span>
                </div>
                <div class="title-right" @click="handleDel(item.projectRecordId)">
                  <img
                    src="@/assets/img/user/del.png"
                    width="100%"
                    height="100%"
                    @dragstart.prevent
                    class="img-setting"
                  />
                  <span class="customer">删除</span>
                </div>
              </div>
            </div>
            <div class="item-bottom">
              <div class="line"></div>
              <div class="content-item">
                <span class="info-title">
                  <span>时间</span>
                  <span class="title">{{ item.visitDate }}</span>
                </span>
              </div>
              <div class="content-item">
                <span class="info-title">
                  <span>地点</span>
                  <span class="title">{{ item.address }}</span>
                </span>
              </div>
              <div class="content-item">
                <span class="info-title">
                  <span>客户方人员</span>
                  <span class="title">
                    <el-table
                      :data="item.personnelList"
                      style="width: 100%"
                      header-cell-class-name="table_header"
                    >
                      <el-table-column
                        prop="personnelName"
                        label="姓名"
                        :show-overflow-tooltip="true"
                        align="center"
                      ></el-table-column>
                      <el-table-column
                        prop="personnelContact"
                        label="联系方式"
                        align="center"
                      ></el-table-column>
                    </el-table>
                  </span>
                </span>
              </div>
              <div class="content-item">
                <span class="info-title">
                  <span>跟踪交流情况</span>
                  <span class="title">{{ item.resultInfo }}</span>
                </span>
              </div>
              <div class="content-item">
                <span class="info-title">
                  <span>客户需求</span>
                  <span class="title">{{ item.demandInfo }}</span>
                </span>
              </div>
              <div class="content-item">
                <span class="info-title">
                  <span>需求响应情况</span>
                  <span class="title">{{ item.demandResponse }}</span>
                </span>
              </div>
              <div class="content-item">
                <span class="info-title">
                  <span>客户反馈</span>
                  <span class="title">{{ item.feedback }}</span>
                </span>
              </div>
              <div class="content-item">
                <span class="info-title">
                  <span>备注</span>
                  <span class="title">{{ item.remarks }}</span>
                </span>
              </div>
              <div class="content-item">
                <span class="info-title">
                  <span>附件</span>
                  <span class="title">
                    <div
                      class="view-style"
                      v-for="(item2, index2) in item.attachmentList"
                      :key="index2"
                      @click="handleViewFile(item2.fileId)"
                    >
                      {{ item2.fileName }}
                    </div>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 新增跟踪记录   dialog -->
      <Modal :visible.sync="visible" @reset="resetFields" class="dialog-style">
        <div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">
          {{ title }}
        </div>
        <el-form
          :model="dataPO"
          :rules="rules"
          ref="form"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="拜访时间" prop="visitDate" class="title-item">
                <el-date-picker
                  type="date"
                  placeholder="请选择拜访时间"
                  v-model="dataPO.visitDate"
                  value-format="yyyy-MM-dd"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地点" prop="address" class="title-item">
                <el-input
                  v-model.trim="dataPO.address"
                  placeholder="请输入地点"
                  maxlength="100"
                  :show-word-limit="true"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="拜访人员" prop="" class="title-item">
                <el-table
                  :data="dataPO.recordPersonnelList"
                  style="width: 100%"
                  header-cell-class-name="table_header"
                >
                  <el-table-column
                    prop="personnelName"
                    label="姓名"
                    :show-overflow-tooltip="true"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="personnelContact"
                    label="联系方式"
                    align="center"
                  ></el-table-column>
                </el-table>
                <div class="input" v-show="inputFlag">
                  <el-input
                    v-model.trim="data.personnelName"
                    placeholder="请输入姓名"
                    @change="handleInputName"
                    maxlength="50"
                    :show-word-limit="true"
                  ></el-input>
                  <el-input
                    v-model.trim="data.personnelContact"
                    placeholder="请输入联系方式"
                    @change="handleInputPhone"
                  ></el-input>
                </div>
                <div class="add" @click="handleAddPeople()" v-show="!inputFlag">
                  <img
                    src="@/assets/img/user/jia.png"
                    width="100%"
                    height="100%"
                    @dragstart.prevent
                    class="img-setting"
                  />
                </div>
                <div class="add" @click="handleReducePeople()" v-show="inputFlag">
                  <img
                    src="@/assets/img/user/jian.png"
                    width="100%"
                    height="100%"
                    @dragstart.prevent
                    class="img-setting"
                  />
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="跟踪交流情况" prop="resultInfo" class="title-item">
                <el-input
                  type="textarea"
                  v-model.trim="dataPO.resultInfo"
                  placeholder="请输入跟踪交流情况"
                  :rows="3"
                  maxlength="300"
                  :show-word-limit="true"
                  resize="none"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="客户需求" prop="demandInfo" class="title-item">
                <el-input
                  type="textarea"
                  v-model.trim="dataPO.demandInfo"
                  placeholder="请输入客户需求"
                  :rows="3"
                  maxlength="300"
                  :show-word-limit="true"
                  resize="none"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="需求响应情况" prop="demandResponse" class="title-item">
                <el-input
                  type="textarea"
                  v-model.trim="dataPO.demandResponse"
                  placeholder="请输入需求响应情况"
                  :rows="3"
                  maxlength="300"
                  :show-word-limit="true"
                  resize="none"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="客户反馈" prop="feedback" class="title-item">
                <el-input
                  type="textarea"
                  v-model.trim="dataPO.feedback"
                  placeholder="请输入客户反馈"
                  :rows="3"
                  maxlength="300"
                  :show-word-limit="true"
                  resize="none"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注" prop="remarks" class="title-item">
                <el-input
                  type="textarea"
                  v-model.trim="dataPO.remarks"
                  placeholder="请输入备注信息，限300字"
                  :rows="3"
                  maxlength="300"
                  :show-word-limit="true"
                  resize="none"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="附件" prop="file" class="upload-item">
                <el-upload
                  ref="uploadReport"
                  class="upload-demo"
                  drag
                  :action="uploadFileUrl"
                  :headers="headerObj"
                  accept=".rar, .zip, .jpg, .pdf, .doc, .docx"
                  :on-remove="handleRemove"
                  :on-preview="handlePreviewReport"
                  :on-change="handleChangeReport"
                  :on-success="handleReportSuccess"
                  :before-upload="beforeReportUpload"
                  :file-list="fileList"
                  v-loading="uploadLoading"
                >
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text"><em>点击</em>或将文件拖拽到这里上传</div>
                  <div slot="file" slot-scope="{ file }">
                    <div class="file-upload-style" @click="handlePreviewReport(file)">
                      <div class="file-img">
                        <img
                          src="../../../../../../../assets/img/user/order-light.png"
                          alt=""
                        />
                      </div>
                      <span class="fille-name">{{ file.fileName }}</span>
                      <i
                        class="el-icon-close card-panel-icon icon-style"
                        @click.stop="handleRemove(file)"
                      />
                    </div>
                  </div>
                  <div class="el-upload__tip" slot="tip">
                    支持rar/zip/jpg/pdf/doc/docx格式
                    <div>文件大小不超过50M</div>
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item class="btn-item">
                <el-button @click="cancel()">取消</el-button>
                <el-button
                  type="primary"
                  @click="submitForm('form')"
                  v-loading="submitLoading"
                  >确认</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </Modal>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
import Modal from "@/components/Modal";
import { validateTax } from "@/utils/validate.js";
export default {
  components: {
    Pagination,
    Empty,
    Modal,
  },
  data() {
    return {
      total: 0,
      tableData: [],
      info: {},
      visible: false,
      title: "新增跟踪记录",
      dataPO: {
        recordPersonnelList: [],
        attachmentList: [],
      },
      data: {
        personnelName: "",
        personnelContact: "",
      },
      showWechat: false,
      params: {
        pageNum: 1,
        pageSize: 10,
      },
      rules: {
        address: [{ required: true, message: "请输入地点", trigger: "blur" }],
        visitDate: [{ required: true, message: "请选择拜访时间", trigger: "change" }],
      },
      inputFlag: false,
      fileList: [],
      // attachmentList: [],
      submitLoading: false,
      typeList: [],
      list: [],
      uploadLoading: false,
      uploadFileUrl: process.env.VUE_APP_BASE_URL + "/mds/api/file/upload", // 上传PDF、WORD服务器地址
      headerObj: {
        Authorization: this.$session.getLoginToken(),
      },
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getClientProjectRecord();
    this.ClientProject();
  },
  methods: {
    // 获取上面数据
    ClientProject() {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.3)",
        fullscreen: true,
      });
      this.$api.personalCenter
        .getProjectType()
        .then((res) => {
          this.typeList = res.data;
          this.$api.personalCenter
            .ClientProject({
              projectId: this.$route.query.id,
            })
            .then((response) => {
              this.info = response.data;
              // this.typeList.forEach(items => {
              //   if (response.data.projectType == items.categoryId) {
              //     this.info.projectTypeName = items.categoryName;
              //     this.$forceUpdate();
              //   }
              // });
              loading.close();
            })
            .catch((msg) => {
              if (msg?.msg) {
                this.$message.error(msg?.msg);
              }
              loading.close();
            });
        })
        .catch((msg) => {
          loading.close();
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
    // 获取下面列表
    getClientProjectRecord() {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.3)",
        fullscreen: true,
      });
      this.$api.personalCenter
        .getClientProjectRecord({
          projectId: this.$route.query.id,
        })
        .then((res) => {
          this.tableData = res.rows;
          this.total = res.total;
          loading.close();
        })
        .catch((msg) => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
          loading.close();
        });
    },
    // 获取类型列表
    // getProjectType() {
    //   this.$api.personalCenter
    //     .getProjectType()
    //     .then(res => {
    //       this.typeList = res.data;
    //     })
    //     .catch(msg => {
    //       if (msg?.msg) {
    //   this.$message.error(msg?.msg);
    // }
    //     });
    // },
    // 查看附件
    handleViewFile(val) {
      this.$api.personalCenter
        .getFileInfo({ fileId: val })
        .then((res) => {
          let url =
            process.env.VUE_APP_FILEURL + res.data.fileDir + "/" + res.data.fileKey;
          window.open(url, "_blank");
        })
        .catch((msg) => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
    // 新增拜访人员
    handleAddPeople() {
      this.inputFlag = true;
    },
    // 收起输入框
    handleReducePeople() {
      this.inputFlag = false;
      this.data = {};
    },
    // 输入姓名
    handleInputName(val) {
      if (this.data.personnelName && this.data.personnelContact) {
        const reg =
          "^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-9])|(18[0-9])|166|198|199|191|195|193|(147))\\d{8}$";
        if (new RegExp(reg).test(this.data.personnelContact)) {
          let obj = JSON.parse(JSON.stringify(this.data));
          this.dataPO.recordPersonnelList.push(obj);
          this.$message.success("拜访人员信息添加成功！");
          this.data = {};
          this.inputFlag = false;
        } else {
          this.$message.error("请输入正确的手机号");
          return false;
        }
      }
      // if (!this.data.personnelName) {
      //   this.$message.error("请输入姓名");
      // }
      // if (!this.data.personnelContact) {
      //   this.$message.error("请输入电话");
      // }
    },
    // 输入电话
    handleInputPhone(val) {
      if (this.data.personnelName && this.data.personnelContact) {
        const reg =
          "^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-9])|(18[0-9])|166|198|199|191|195|193|(147))\\d{8}$";
        if (new RegExp(reg).test(this.data.personnelContact)) {
          let obj = JSON.parse(JSON.stringify(this.data));
          this.dataPO.recordPersonnelList.push(obj);
          this.$message.success("拜访人员信息添加成功！");
          this.data = {};
          this.inputFlag = false;
        } else {
          this.$message.error("请输入正确的手机号");
          return false;
        }
      }
      // if (!this.data.personnelName) {
      //   this.$message.error("请输入姓名");
      // }
      // if (!this.data.personnelContact) {
      //   this.$message.error("请输入电话");
      // }
    },
    // 新增跟踪记录
    handleAdd() {
      this.visible = true;
      this.title = "新增跟踪记录";
      // this.resetFields();
    },
    // 修改按钮
    handleEdit(row) {
      this.visible = true;
      this.title = "编辑追踪记录";
      this.dataPO = JSON.parse(JSON.stringify(row));
      this.dataPO.recordPersonnelList = JSON.parse(JSON.stringify(row.personnelList));
      this.fileList = JSON.parse(JSON.stringify(row.attachmentList));
      // this.resetFields();
    },
    // 删除按钮
    handleDel(val) {
      this.$confirm("确认删除该条项目信息吗？", "提示", {
        confirmButtonText: "确认删除",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api.personalCenter
            .deleteClientProjectRedord({ projectRedordIds: val })
            .then((res) => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getClientProjectRecord();
              this.ClientProject();
            })
            .catch((msg) => {
              if (msg?.msg) {
                this.$message.error(msg?.msg);
              }
            });
        })
        .catch(() => {});
    },
    // 资料报告库  上传资料
    handleChangeReport(file, fileList) {},
    handleReportSuccess(res, file) {
      if (res.code == 200) {
        this.uploadLoading = false;
        this.$message.success("上传成功");
        this.dataPO.attachmentList.push(res.data);
        this.fileList.push({
          url: res.data.url,
          fileName: res.data.fileName,
        });
      } else {
        this.$refs.uploadReport.handleRemove(file);
        this.uploadLoading = false;
        this.$message.error(res.msg);
      }
    },
    beforeReportUpload(file) {
      this.uploadLoading = true;
      let name = file.name;
      let namearr = name.split(".");
      if (namearr.length > 2) {
        this.uploadLoading = false;
        this.$message.error("文件名称不能携带特殊字符！");
        return false;
      } else if (
        name.toLowerCase().indexOf("pdf") == -1 &&
        name.toLowerCase().indexOf("doc") == -1 &&
        name.toLowerCase().indexOf("docx") == -1 &&
        name.toLowerCase().indexOf("rar") == -1 &&
        name.toLowerCase().indexOf("zip") == -1 &&
        name.toLowerCase().indexOf("jpg") == -1
      ) {
        this.uploadLoading = false;
        this.$message.error("请上传rar/zip/jpg/pdf/doc/docx格式文件！");
        return false;
      } else {
        const isLt50M = file.size / 1024 / 1024 < 50;
        if (!isLt50M) {
          this.uploadLoading = false;
          this.$message.error("上传文件大小不能超过50M!");
        }
        return isLt50M;
      }
    },
    handlePreviewReport(file) {
      if (file.url) {
        window.open(file.url, "_blank");
      } else {
        this.$api.personalCenter
          .getFileInfo({ fileId: file.fileId })
          .then((res) => {
            let url =
              process.env.VUE_APP_FILEURL + res.data.fileDir + "/" + res.data.fileKey;
            window.open(url, "_blank");
          })
          .catch((msg) => {
            if (msg?.msg) {
              this.$message.error(msg?.msg);
            }
          });
      }
    },
    // 移除文件
    handleRemove(file, fileList) {
      if (this.dataPO.attachmentList) {
        this.dataPO.attachmentList.forEach((item, index) => {
          if (item.fileName == file.fileName) {
            this.dataPO.attachmentList.splice(index, 1);
            this.fileList.splice(index, 1);
          }
        });
      } else {
        this.dataPO.attachmentList = [];
      }
    },
    // 预览文件
    handlePreview(file) {
      window.open(file.url, "_blank");
    },
    // 关闭弹窗时重置表单
    resetFields() {
      this.$refs.form.resetFields();
      this.dataPO = {
        recordPersonnelList: [],
        attachmentList: [],
      };
      this.data = {
        personnelName: "",
        personnelContact: "",
      };
      this.fileList = [];
      this.inputFlag = false;
    },
    // 表单提交事件
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          if (this.dataPO.projectRedordId) {
            this.$api.personalCenter
              .updateClientProjectRecord({
                ...this.dataPO,
                projectId: this.$route.query.id,
              })
              .then((res) => {
                this.visible = false;
                this.submitLoading = false;
                this.$message.success("修改成功");

                this.dataPO = {
                  recordPersonnelList: [],
                  attachmentList: [],
                };
                this.data = {
                  personnelName: "",
                  personnelContact: "",
                };
                this.fileList = [];
                this.inputFlag = false;

                this.getClientProjectRecord();
                this.ClientProject();
              })
              .catch((err) => {
                this.submitLoading = false;
                this.$message.error("提交失败");
              });
          } else {
            this.$api.personalCenter
              .addClientProjectRecord({
                ...this.dataPO,
                projectId: this.$route.query.id,
              })
              .then((res) => {
                this.visible = false;
                this.submitLoading = false;
                this.$message.success("提交成功");

                this.dataPO = {
                  recordPersonnelList: [],
                  attachmentList: [],
                };
                this.data = {
                  personnelName: "",
                  personnelContact: "",
                };
                this.fileList = [];
                this.inputFlag = false;

                this.getClientProjectRecord();
                this.ClientProject();
              })
              .catch((err) => {
                this.submitLoading = false;
                this.$message.error("提交失败");
              });
          }
        } else {
          this.$message.error("提交失败!");
          this.submitLoading = false;
          return false;
        }
      });
    },
    // 表单取消
    cancel() {
      this.visible = false;
      this.resetFields();
      this.data = {};
      this.inputFlag = false;
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
